<template>
<div class="club-page-background">
  <div class="club-page-full">
    <div class="club-name">
      {{club['Club Name']}}
      <i  v-if="club['Verified']" class="fas fa-check-circle"></i>
      <div class="club-category">
        {{club['Category']}}
      </div>
    </div>
    <div class="club-summary" 
      v-html="club['Club Summary']">
    </div>

    <div class="club-info">
      <div class="club-extra-info-row" v-for="property in Object.keys(club).filter(p => (p != '_id' && p != 'Club Name' && p != 'Category' && p != 'Club Summary' && p != 'Reviews' && p != 'Verified')) " :key="property">
        <div v-if="typeof club[property] === 'string' && (club[property].includes('www.') || club[property].includes('http://') || club[property].includes('https://'))">
          <div class="club-info-label">
            {{property.toUpperCase()}}
          </div>
          <a :href="club[property]">{{shrinkUrl(club[property])}}</a>
        </div>
        <div v-else>
          <div class="club-info-label">
            {{property.toUpperCase()}}
          </div>
          {{club[property]}}
        </div>
      </div>
    </div>

    <div class="club-rating">
      <span class="club-rating-title">
        Rating: <strong>{{clubRating}}</strong>
      </span> 
    </div>
    <div class="club-review-count">
      {{' ('+(club['Reviews'] ? club['Reviews'].length : 'No')+' Reviews)'}}
    </div>

    <div class="reviews-panel">
      <div v-if="club['Reviews']">
        <div v-for="review in club['Reviews'].slice(pos,pos+6)" class="review-each" :key="review.createdAt">
          <div>
            <i class="fas fa-user-graduate"></i>
            <span class="review-name">{{review.name}}</span> 
            <span class="review-date">{{cleanDate(review.createdAt)}}</span>
          </div>
          <div class="review-rating">
            <span v-for="i in 5" :key="i">
              <i v-if="review.rating >= i" class="fas fa-star"></i>
              <i v-else class="far fa-star"></i>
            </span>
          </div>
          <div class="review-review">
            {{review.review}}
          </div>
          <div class="review-helpful">
            {{review.helpful}} {{(review.helpful == 1) ? ' person':' people'}} found this helpful
          </div>
          <button v-if="!review.helpfulPressed" class="review-helpful-button" @click="markHelpful(review)">Helpful</button>
        </div>
      </div>
      <div v-if="!club['Reviews']">
        No reviews for "{{club['Club Name']}}". Be the first one!
      </div>
    </div>
    <div v-if="club['Reviews']">
      <i :class="pos > 0 ? 'fas fa-caret-left':'fas fa-caret-left caret-gray'" @click="reducePos"></i>
      <i  :class="(club['Reviews'] && (pos + 6) < club['Reviews'].length) ? 'fas fa-caret-right' : 'fas fa-caret-right  caret-gray'" @click="increasePos"></i>
    </div>

    <button v-if="!reviewAdded" class="add-review-button" @click="openReviewModal">
      Add Review
    </button>
    <div v-if="error">
      Sorry, the the club you are trying to find does not exist
    </div>

    <div id="review-modal-backdrop" @click="closeReviewModal"/>
    <div id="review-modal">
      <i class="fas fa-times" @click="closeReviewModal"></i>
      <div class="review-modal-padding">
        <div class="review-modal-club-name">
          <strong>{{club['Club Name']}}</strong>
        </div>
        <input type="text" v-model="name" placeholder="Name" id="review-name"/>
        <input type="number" v-model="rating" placeholder = "Rating (1-5)" min="1" max="5" id="review-rating">
        <textarea v-model="review" placeholder="Review" rows="9" id="review-review"/>
        <button class="add-review-button submit-button" @click="submitReview">Submit</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ["clubId"],
  data(){
    return{
      club: {},
      error: false,
      pos: 0,
      reviewAdded: false,

      name: '',
      rating: null,
      review: '',
    }
  },
  created(){
    this.fetchClub();
  },
  computed: {
    clubRating(){
      let sum = 0;
      if(!this.club.Reviews)
        return '0.0';
      this.club.Reviews.forEach(element => {
        sum += element.rating;
      }); 
      return parseFloat((sum) / (this.club.Reviews.length)).toFixed(1);
    }
  },
  watch: {
    clubId(){
      this.fetchClub();
    },
    rating(){
      if(this.rating != '' && (isNaN(this.rating) || this.rating < 1 || this.rating > 5))
        this.rating = null;
      document.getElementById('review-rating').style.borderBottom = '1px solid #D0D0D0';
    },
    review(){
      if(this.review.length > 500)
        this.review = this.review.substring(0,500);
      document.getElementById('review-review').style.borderBottom = '1px solid #D0D0D0';
    },
    name(){
      if(this.name.length > 50)
        this.name = this.name.substring(0,50);
      document.getElementById('review-name').style.borderBottom = '1px solid #D0D0D0';
    }
  },
  methods: {
    fetchClub(){
      this.error = false;
      fetch('/api/clubs/'+this.clubId)
      .then(res => res.json())
      .then(data => {
        if(data == 'Error')
          this.error = true;
        else{
          if(data.Reviews)
            data.Reviews.sort((a,b) => (b.helpful-a.helpful) != 0 ? (b.helpful-a.helpful) : (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
          this.club = data;
        }
      });
    },
    shrinkUrl(url){
      if(url.includes('www.'))
        return url.substring(url.indexOf('www.')+4);
      else if(url.includes('http://'))
        return url.substring(url.indexOf('http://')+7);
      else if(url.includes('https://'))
        return url.substring(url.indexOf('https://')+8);
      else
        return 'shrinkURL() -> err';
    },
    openReviewModal(){
      document.getElementById('review-modal').style.pointerEvents = 'all';
      document.getElementById('review-modal').style.opacity = '1';
      document.getElementById('review-modal-backdrop').style.zIndex = 25;
      setTimeout(() => document.getElementById('review-modal-backdrop').style.opacity = '.3', 100);
    },
    closeReviewModal(){
      document.getElementById('review-modal').style.pointerEvents = 'none';
      document.getElementById('review-modal').style.opacity = '0';
      document.getElementById('review-modal-backdrop').style.zIndex = -25;
      document.getElementById('review-modal-backdrop').style.opacity = '0';
    },
    checkReviewFields(){
      if(!this.name){
        document.getElementById('review-name').style.borderBottom = '2px solid red';
        return false;
      }
      if(!this.rating){
        document.getElementById('review-rating').style.borderBottom = '2px solid red';
        return false;
      }
      if(!this.review){
        document.getElementById('review-review').style.borderBottom = '2px solid red';
        return false;
      }
      return true;
    },
    submitReview(){
      if(!this.checkReviewFields())
        return;
      this.closeReviewModal();
      const options = {
        method: 'post',
        body: JSON.stringify({
          name: this.name,
          rating: this.rating,
          review: this.review,
        }),
        headers: {'Content-Type': 'application/json'}
      };
      fetch('/api/clubs/review/'+this.club._id, options)
        .then(res => res.json())
        .then(() => this.fetchClub());
      this.reviewAdded = true;
    },
    cleanDate(d){
      var options = {year: 'numeric', month: 'long', day: 'numeric' };
      return (new Date(d)).toLocaleDateString("en-US", options);
    },
    reducePos(){
      this.pos -= 6;
    },
    increasePos(){
      this.pos += 6;
    },
    markHelpful(review){
      review.helpfulPressed = true;
      review.helpful += 1;
      
      const options = {
        method: 'post',
        body: JSON.stringify({
          date: review.createdAt,
        }),
        headers: {'Content-Type': 'application/json'}
      };
      fetch('/api/clubs/review/'+this.club._id+'/helpful', options)
    }
  }
}
</script>

<style scoped>
.club-page-background{
  background: white;
}
.club-page-full{
  padding: 20px 20px 80px;
  max-width: 800px;
  margin: 0 auto;
}
.club-name{
  font-size: 30px;
  margin-bottom: 30px;
  padding: 10px;
  color: var(--orange-accent);
}
.club-category{
  font-size: 12px;
  font-style: italic;
  color: gray;
}
.club-summary{
  margin-bottom: 60px;
  overflow-x: clip;
}
.rating-and-club-info{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}
.club-rating{
  text-align: center;
  margin-bottom: 0px;
  font-size: 20px;
}
.club-rating-title{
  color: var(--primary-color);
}
.club-review-count{
  font-size: 12px;
  margin-bottom: 10px;
  text-align: center;
}
.club-info{
  background: white;
  margin-bottom: 60px;
  border: 1px solid var(--lightest-gray);
}
.club-extra-info-row{
  text-align: left;
  padding: 12px 20px 12px 20px;
  color: #333333;
  word-break: break-all;
}
.club-info-label{
  color: #666666;
  font-size: 10px;
  margin-bottom: 5px;
}
.review-header{
  text-align: left;
}
.reviews-panel{
  padding: 20px;
  margin-bottom: 20px;
  background: white;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}
.add-review-button{
  cursor: pointer;
  background: var(--primary-color);
  border-radius: 4px;
  color: white;
  transition: .3s all;
  border: none;
  padding: 10px;
  width: fit-content;
  margin-top: 30px;
}
.add-review-button:hover{
  background: var(--primary-color-light);
}
#review-modal{
  position: fixed;
  height: 50vh;
  width: min(100% - 40px, 800px);
  z-index: 50;
  background: white;
  top: 25vh;
  opacity: 0;
  pointer-events: none;
  transition: .3s all;
  border-radius: 6px;
}
#review-modal-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -25;
  background: black;
  opacity: 0;
  transition: .3s all;
}
.fa-times{
  font-size: 20px;
  color: #b5b5b5;
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
}
.review-modal-padding input, .review-modal-padding textarea{
  margin-bottom: 20px;
  border: none;
  border-bottom: 1px solid #D0D0D0;
  padding: 5px;
  font-family: inherit;
  font-size: 16px;
  resize: none;
}
.review-modal-padding input:active{
  border: none;
}
.review-modal-club-name{
  margin-bottom: 30px;
}
.review-modal-padding{
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.submit-button{
  margin: 0 auto;
  padding: 10px 20px;
}
.fa-user-graduate{
  font-size: 30px;
  margin-right: 20px;
  color: #a7b5b4;
}
.review-each{
  text-align: left;
  border-bottom: 1px solid#bbbbbb;
  padding: 40px 0;
}
.review-name{
  font-size: 14px;
}
.review-date{
  font-size: 14px;
  color:#979797;
  text-align: right;
  float: right;
}
.review-review{
  margin-top: 15px;
  font-size: 15px;
}
.review-rating{
  display: inline-block;
  margin-top: 7px;
}
.fa-star{
  color: var(--orange-accent);
  transition: .3s all;
}
.fa-star:hover{
  transform: rotate(20deg);
}
.fa-caret-left, .fa-caret-right{
  font-size: 40px;
  color: var(--primary-color);
  cursor: pointer;
  margin: 0 10px 10px;
}
.caret-gray{
  pointer-events: none;
  cursor: initial;
  color: lightgray;
}
.review-helpful{
  color: #565656;
  margin-top: 20px;
  font-style: italic;
}
.review-helpful-button{
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  background: white;
  color: black;
  margin-top: 6px;
  border-radius: 8px;
  border: 1px solid gray;
  box-shadow: rgb(100 100 111 / 20%) 0px 0px 29px 0px;
  transition: .3s all;
}
.review-helpful-button:hover{
  background: #dcdcdc;
}

</style>